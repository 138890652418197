import React, { Component } from 'react'

import PropTypes from 'prop-types'

import './Styles/ScoreCardStyles.css'

class ScoreCard extends Component {

  render() {

    return (
      <div className="ScoreCardContainer">
        { this.props.character &&
          <img className="ScoreCardImage" src={ this.props.character.image } />
        }
        <div className="ScoreCardBackground" />
        <div className="ScoreCardInfo">
          <span className="ScoreCardUserName">
            { this.props.user ? this.props.user.name : null }
          </span>
          { /* <span className="ScoreCardUserPoints">
            { this.props.score } Pts
          </span> */ }
        </div>

      </div>
    )
  }

}

ScoreCard.propTypes = {
  user: PropTypes.object,
  character: PropTypes.object,
  score: PropTypes.number.isRequired,
}

export default ScoreCard

import React, { Component } from 'react'

import FormInput from '../Components/FormInput'

import backgroundImage from '../Assets/back-launch-logo.png'
import userIcon from '../Assets/photo.png'
import ovalIcon from '../Assets/oval.png'
import checkIcon from '../Assets/check-oval.png'
import Logo from '../Components/Logo'

import validate from 'validate.js'
import { first } from 'lodash'
import './Styles/Signature.css'

import { connect } from 'react-redux'

import GameActions from '../Redux/Game'

import './Styles/UserDataScreenStyles.css'
import './Styles/GeneralStyles.css'

class UserDataScreen extends Component {

  constructor(props) {
    super(props)

    this.state = {
      name: null,
      lastname: null,
      termsChecked: false,
    }
  }

  componentDidMount() {
    this.constraints = {
      name: {
        presence: {
          allowEmpty: false,
          message: 'es obligatorio',
        }
      }
    }
  }

  saveData(event) {

    event.preventDefault()

    if (!this.state.termsChecked) {
      return
    }

    this.setState({
      nameError: null,
      lastnameError: null,
    })

    const values = {
      name: this.state.name,
      lastname: this.state.lastname
    }

    const validations = validate(values, this.constraints)

    if (!validations) {
      this.props.startGameForUser(
        this.state.name,
        this.state.lastname,
        null,
        null,
        null
      )

      this.props.history.push({ pathname: '/character' })

      return
    }

    if (validations.name) this.setState({ nameError: first(validations.name) })
    if (validations.lastname) this.setState({ lastnameError: first(validations.name) })

  }

  render() {

    return (
      <div
        className="screen"
        id="user-data-screen"
        style={ { backgroundImage: 'url('+backgroundImage+')', backgroundSize: 'cover', backgroundPosition: 'center' } }>
        <div id="title">
          Regístrate para empezar
        </div>
        <form onSubmit={ event => this.saveData(event)}>
          <div id="box-form">
            <img src={userIcon} className="user-icon" />
            <div className="background" />
            <div id="box-container-fields">
              <div className="FormContainerInput">
                <FormInput
                  className="FormInput"
                  label="Nombres"
                  errorValue={ this.state.nameError }
                  onChange={ event => this.setState({ name: event.target.value }) } />
              </div>
              <div className="FormContainerInput">
                <FormInput
                    className="FormInput"
                    label="Apellidos"
                    errorValue={ this.state.lastnameError }
                    onChange={ event => this.setState({ lastname: event.target.value }) }  />
              </div>

              <div className="Terms">
                Términos de Uso y Condiciones <br/><br/>

                *Para establecer, mantener y profundizar la relación contractual, el envío y recepción de la información, evaluar el riesgo, y para efectuar labores de mercadeo o publicidad el CLIENTE expresamente y de manera permanente autoriza a Allianz () para obtener, recolectar, almacenar y usar la información financiera, datos personales, comerciales, privados, semiprivados o de cualquier naturaleza del (CLIENTE/USUARIO) que éste suministre o a los que tuviere acceso Allianz par cualquier medio sin lugar a pagos ni retribuciones, (ii) para utilizar, intercambiar, circular y en general enviar y recibir, por cualquier medio la información financiera, dato personal, comercial, privado, semiprivado o de cualquier naturaleza del CLIENTE contenido en las bases de datos de Allianz con su matriz, sus subordinadas y con terceros que cuenten con acuerdos comerciales con Allianz, incluyendo pero sin limitarse a entidades vigiladas por la Superintendencia Financiera y/o entidades del sector real, y (i) para Allianz, su matriz, sus subordinadas y con terceros que cuenten con acuerdos comerciales con Allianz, incluyendo pero sin limitarse a entidades vigiladas por la Superintendencia Financiera y/o entidades del sector real puedan consultar, obtener, recolectar, almacenar, analizar y usar la información suministrada por el CLIENTE con los operadores de información y/o bancos de datos.
              </div>
              <div id="terms-container">

                <div onClick={ () => this.setState({ termsChecked: !this.state.termsChecked }) } >
                  <img src={this.state.termsChecked ? checkIcon : ovalIcon } />
                </div>

                <div>
                  <p>Acepto términos de uso y condiciones</p>
                </div>

              </div>



            </div>
          </div>
          <div style={{ height: '60px' }}></div>
          <input id="submit" type="submit" value="empezar" />
          <Logo />
        </form>




      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    startGameForUser: (name, email, dni, phone, signature) =>
      dispatch( GameActions.startGameForUser(name, email, dni, phone, signature) )
  }
}

export default connect(null, mapDispatchToProps) (UserDataScreen)

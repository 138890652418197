import React, { Component } from 'react'

import logoImage from '../Assets/logo-allianz.png'

import './Styles/LogoStyles.css'

class Logo extends Component {

  render() {
    return (
      <div className="LogoContainer">
        <img src={ logoImage } />
      </div>
    )
  }
}

export default Logo

import React, { Component } from 'react'

import backgroundImage from '../Assets/back-launch.svg'

import Logo from '../Components/Logo'


import { connect } from 'react-redux'
import GameActions from '../Redux/Game'

import './Styles/GeneralStyles.css'
import 'loaders.css/loaders.min.css'

import './Styles/DownloadDataScreenStyles.css'

import Loader from 'react-loaders'

class DownloadDataScreen extends Component {

  componentDidMount() {
    this.props.fetchQuestions()
  }

  componentDidUpdate(prevProps) {
    console.log('prevProps', prevProps)
    console.log('this.props', this.props)
    if ( !prevProps.finishedCategoriesRequest && this.props.finishedCategoriesRequest ) {
      this.props.history.push({ pathname: '/roullete' })
    }
  }

  render() {

    return (
      <div
        style={ { backgroundImage: 'url('+backgroundImage+')', backgroundSize: 'cover', backgroundPosition: 'center' } }
        className="screen">
        <div className="ParentContentContainer">
          <Logo />
          <div className="ContainerLoader">
            <Loader
              type="ball-beat"
              active={ this.props.loadingQuestionsByCategories }
              color="#fff"
              innerClassName="LoaderData"
              size="lg" />
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    categories: state.game.categories,
    categoriesError: state.game.error,
    finishedCategoriesRequest: state.game.finishedCategoriesRequest,
    loadingQuestionsByCategories: state.game.loadingQuestionsByCategories
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchQuestions: () => dispatch( GameActions.fetchQuestions() )
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (DownloadDataScreen)

import React, { Component } from 'react'

import Wheel from '../Components/Wheel/Wheel'
import backgroundImage from '../Assets/back-launch-logo.png'
import roulleteBase from '../Assets/ruleta-base.png'
import Logo from '../Components/Logo'
import ScoreCard from '../Components/ScoreCard'

import { connect } from 'react-redux'

import GameActions from '../Redux/Game'

import { Howl } from 'howler'
import backgroundAudioFile from '../Assets/sounds/house.mp3'

import './Styles/GeneralStyles.css'
import './Styles/RoulleteScreenStyles.css'

class RoulleteScreen extends Component {

  backgroundSound = new Howl({ src: [ backgroundAudioFile ], volume: 0.5, loop: true })

  componentDidMount() {
    this.backgroundSound.play()
  }

  componentWillUnmount() {
    this.backgroundSound.fade(1, 0, 3000)
  }

  render() {

    return (
      <div
        style={ { backgroundImage: 'url('+backgroundImage+')', backgroundSize: 'cover', backgroundPosition: 'center' } }
        className="screen">
        <div>
          <Logo />
        </div>

        <ScoreCard
          user={ this.props.user }
          score={ this.props.score }
          character={ this.props.character } />
        <div className="RoulleteContainer">
          <img src={ roulleteBase } />
          { this.props.categories &&
            <Wheel
              style={ { paddingTop: 103 } }
              slices={this.props.categories}
              width={770}
              friction={0.4}
              onSelectedWinnerSlice={ index => {
                console.log('Event: category: ', this.props.categories[index], index)
                this.props.addPrize( this.props.categories[index].name )
                this.props.addPlayer(this.props.user)
                this.props.selectCategory(this.props.categories[index])
                this.props.history.push({ pathname: '/question' })
              }} />
          }
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    categories: state.game.categories,
    user: state.game.user,
    character: state.game.character,
    score: state.game.score,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addPrize: prize => dispatch( GameActions.addPrize(prize)),
    addPlayer: user => dispatch( GameActions.addPlayer(user, null) ),
    selectCategory: category => dispatch( GameActions.selectCategory(category) )
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (RoulleteScreen)

import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField'
import './Styles/FormInputStyles.css'
import classNames from 'classnames'

const theme = createTheme({
  components: {
    // Name of the component
    MuiTextField: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          padding: 0,
          opacity: 0.8,
          display: 'flex',
          flex: 1,
          borderRadius: 100,
          backgroundColor: '#d7d7d7',
          border: '1px solid #ced4da',
          fontSize: 16,
          paddingLeft: 6,
          paddingRight: 6,
          marginTop: 10,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          marginLeft: 20,
          paddingTop: 5,

        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          paddingLeft: 20,
          paddingTop: 10,
          flex: 1,
          zIndex: 999,
          userSelect: 'none',
          pointerEvents: 'none',
          width: '100%',
          alignSelf: 'center',
          // height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:focus': {
            color: 'green',
          },
        }
      }
    },
    MuiFormLabelFocus: {
      styleOverrides: {
        root: {
          flex: 1,
          color: 'gray !important',
          paddingLeft: 0,
          marginLeft: 10,
          justifyContent: 'flex-start',
        }
      }
    }
  },
});

// const styles = theme => ({
//
//   bootstrapRoot: {
//     padding: 0,
//     opacity: 0.8,
//     display: 'flex',
//     flex: 1,
//   },
//
//   bootstrapInput: {
//     flex: 1,
//     borderRadius: 100,
//     backgroundColor: '#d7d7d7',
//     border: '1px solid #ced4da',
//     fontSize: 16,
//     paddingLeft: 6,
//     paddingRight: 6
//   },
//   bootstrapFormLabel: {
//     flex: 1,
//     zIndex: 999,
//     userSelect: 'none',
//     pointerEvents: 'none',
//     width: '100%',
//     alignSelf: 'center',
//     // height: '100%',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     textAlign: 'center',
//     '&:focus': {
//       color: 'green',
//     },
//
//
//     // marginTop: 20
//
//   },
//   formLabelFocus: {
//     flex: 1,
//     color: 'gray !important',
//     paddingLeft: 0,
//     marginLeft: 10,
//     justifyContent: 'flex-start',
//   }
// })

function FormInput(props) {
  const { classes } = props

  return (
    <div
      style={props.style}
      className={ classNames({
        "FormInputContainer": true,
        [props.className]: props.className !== undefined })} >
        <ThemeProvider theme={theme}>
          <TextField
            style={{ flex: 1 }}
            label={props.label}
            fullWidth
            variant="standard"
            onChange={ props.onChange }
            InputProps={{
              disableUnderline: true,
            }}
          />
        </ThemeProvider>

    { props.errorValue && <div className="errorField"> { props.errorValue } </div> }
    </div>
  )
}

export default FormInput;

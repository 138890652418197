import React, { Component } from 'react'

import backgroundImage from '../Assets/back-launch-logo.png'
import activityLogo from '../Assets/logo-liderazgo.png'

import Logo from '../Components/Logo'

import { connect } from 'react-redux'
import GameActions from '../Redux/Game'

import classNames from 'classnames'

import './Styles/GeneralStyles.css'
import './Styles/CongratulationsScreenStyles.css'

class CongratulationsScreen extends Component {

  componentDidMount() {
    setTimeout( () => {
      this.props.clearGame()
      this.props.history.push({ pathname: '/' })
    }, 5000)
  }

  render() {
    return (
      <div
        style={ { backgroundImage: 'url('+backgroundImage+')', backgroundSize: 'cover', backgroundPosition: 'center' } }
        className={classNames(['screen', 'CongratulationsScreenContainer']) }>
        <div className="CongratulationsScreenLogo" style={ { paddingTop: 120, paddingBottom: 120 } }>
          <Logo />
        </div>

        <div>
          { this.props.character &&
            <img style={{ width: 628, marginTop: 400 }} src={ this.props.character.image } />
          }
          <div className="PrizeInfoContainer">
            <div className="PrizeInfo">
              <span className="PrizeInfoName">
                Felicitaciones  { this.props.user ? this.props.user.name : " "}
              </span>
              <span className="PrizeInfoPrize">
                Tu puntaje final es de { this.props.score } puntos
              </span>
            </div>
          </div>

        </div>

        <div>
          <img src={ activityLogo } />
        </div>

      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    user: state.game.user,
    score: state.game.score,
    character: state.game.character,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearGame: () =>
      dispatch( GameActions.clearGame() ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (CongratulationsScreen)
